<template>
  <div>
    <section class="text-gray-700 body-font">
      <div
          class="container flex flex-col px-5 py-16 mx-auto md:flex-row"
      >
        <div
            class="flex flex-col w-full pt-0 mb-16 text-left lg:flex-grow md:w-1/2 xl:mr-20 md:pr-24 md:items-start md:text-left md:mb-0"
        >
          <h2
              class="mb-1 px-1 text-xs font-medium tracking-widest text-white title-font"
          >
            LABYMOD ADDON
          </h2>
          <h1 class="mb-6 text-5xl font-bold text-white leading-snug">
            GlobalChat
          </h1>
          <p
              class="mb-8 text-base leading-relaxed text-white opacity-80 lg:text-left lg:text-1xl"
          >
            A chat addon for the popular LabyMod application that provides you
            with a server-independent chat to communicate with all LabyMod
            players.
          </p>
          <div class="flex justify-center">
            <a class="px-6 py-2 font-semibold text-left text-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none"
               href="/#installation"> Installation</a>
          </div>
        </div>
        <div class="w-5/6 lg:max-w-lg lg:w-full md:w-1/2">
          <img
              width="360"
              height="360"
              class="object-cover object-center rounded-lg"
              src="@/assets/images/logo.png"
          />
        </div>
      </div>
    </section>
    <div class="flex md:flex-row flex-col justify-between py-32">
      <div class="flex flex-row space-x-5 justify-center pt-4">
        <div class="bg-brand-white10 h-20 w-20 p-6 rounded-full">
          <img src="@/assets/images/active.svg"/>
        </div>
        <div class="felx flex-col my-auto justify-start space-y-2">
          <h1 class="text-4xl font-bold">{{ stats.online }}</h1>
          <p class="opacity-80 text-sm">Online Users</p>
        </div>
      </div>
      <div class="flex flex-row space-x-5 justify-center pt-4">
        <div class="bg-brand-white10 h-20 w-20 p-6 rounded-full">
          <img src="@/assets/images/registered.svg"/>
        </div>
        <div class="felx flex-col my-auto justify-start space-y-2">
          <h1 class="text-4xl font-bold">{{ stats.registered }}</h1>
          <p class="opacity-80 text-sm">Registered Users</p>
        </div>
      </div>
      <div class="flex flex-row space-x-5 justify-center pt-4">
        <div class="bg-brand-white10 h-20 w-20 p-6 rounded-full">
          <img src="@/assets/images/chat.svg"/>
        </div>
        <div class="felx flex-col my-auto justify-start space-y-2">
          <h1 class="text-4xl font-bold">{{ stats.messages }}</h1>
          <p class="opacity-80 text-sm">Sent Messages</p>
        </div>
      </div>
    </div>
    <section id="installation" class="text-gray-700 body-font">
      <div
          class="container flex flex-col items-center px-5 py-16 mx-auto lg:px-20 lg:py-24 md:flex-row"
      >
        <div
            class="shadow-2xl rounded-lg w-5/6 mb-10 lg:max-w-lg lg:w-full md:w-1/2 md:mb-0"
        >
          <video
              class="rounded-lg transform transition hover:scale-105 duration-700 ease-in-out"
              autoplay
              loop
              muted
          >
            <source src="@/assets/images/installation.mp4" type="video/mp4"/>
          </video>
        </div>
        <div
            class="flex flex-col lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 md:items-start md:text-left"
        >
          <h2
              class="mb-1 px-1 text-xs font-medium tracking-widest text-white title-font"
          >
            INSTALLATION
          </h2>
          <h1 class="mb-6 text-5xl font-bold text-white leading-snug">
            Getting Started
          </h1>
          <p
              class="mb-8 text-base leading-relaxed text-white opacity-80 lg:text-left lg:text-1xl"
          >
            To start the installation, open the LabyMod Client and navigate to
            the LabyMod Addon Store. <br/>
            Search for the addon "GlobalChat" and download it. Restart your
            client and now you can use the Global Chat.
          </p>
          <div class="w-full py-6">
            <div class="flex">
              <div class="w-1/4">
                <div class="relative mb-2">
                  <div
                      class="w-10 h-10 mx-auto bg-white rounded-full text-lg text-white flex items-center"
                  >
                    <span class="text-center text-dark_gray w-full">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 20 20"
                          class="w-full fill-current"
                      >
                        <path
                            fill-rule="evenodd"
                            d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                        />
                        <path
                            fill-rule="evenodd"
                            d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div class="text-xs text-center text-gray-100 md:text-base">
                  Options
                </div>
              </div>
              <div class="w-1/4">
                <div class="relative mb-2">
                  <div
                      class="absolute flex align-center items-center align-middle content-center"
                      style="
                      width: calc(100% - 2.5rem - 1rem);
                      top: 50%;
                      transform: translate(-50%, -50%);
                    "
                  >
                    <div
                        class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1"
                    >
                      <div
                          class="w-0 bg-green-300 py-1 rounded"
                          style="width: 0%"
                      ></div>
                    </div>
                  </div>
                  <div
                      class="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
                  >
                    <span class="text-center text-dark_gray w-full">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 20 20"
                          class="w-full fill-current"
                      >
                        <path
                            fill-rule="evenodd"
                            d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                        />
                        <path
                            fill-rule="evenodd"
                            d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div class="text-xs text-center text-gray-100 md:text-base">
                  LabyMod Settings
                </div>
              </div>
              <div class="w-1/4">
                <div class="relative mb-2">
                  <div
                      class="absolute flex align-center items-center align-middle content-center"
                      style="
                      width: calc(100% - 2.5rem - 1rem);
                      top: 50%;
                      transform: translate(-50%, -50%);
                    "
                  >
                    <div
                        class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1"
                    >
                      <div
                          class="w-0 bg-green-300 py-1 rounded"
                          style="width: 0%"
                      ></div>
                    </div>
                  </div>
                  <div
                      class="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
                  >
                    <span class="text-center text-dark_gray w-full">
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 20 20"
                          class="w-full fill-current"
                      >
                        <path
                            fill-rule="evenodd"
                            d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                        />
                        <path
                            fill-rule="evenodd"
                            d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div class="text-xs text-center text-gray-100 md:text-base">
                  Addons
                </div>
              </div>
              <div class="w-1/4">
                <div class="relative mb-2">
                  <div
                      class="absolute flex align-center items-center align-middle content-center"
                      style="
                      width: calc(100% - 2.5rem - 1rem);
                      top: 50%;
                      transform: translate(-50%, -50%);
                    "
                  >
                    <div
                        class="w-full bg-gray-200 rounded items-center align-middle align-center flex-1"
                    >
                      <div
                          class="w-0 bg-green-300 py-1 rounded"
                          style="width: 0%"
                      ></div>
                    </div>
                  </div>
                  <div
                      class="w-10 h-10 mx-auto bg-white border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
                  >
                    <span class="text-center text-dark_gray w-full">
                      <svg
                          class="w-full fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                      >
                        <path
                            class="heroicon-ui"
                            d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div class="text-xs text-center text-gray-100 md:text-base">
                  Search and Download
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-700 body-font">
      <div
          class="container items-center flex flex-col px-5 py-16 mx-auto lg:px-20 lg:py-24 md:flex-row"
      >
        <div
            class="flex flex-col w-full pt-0 mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 lg:mr-20 md:items-start md:text-left md:mb-0"
        >
          <h2
              class="mb-1 px-1 text-xs font-medium tracking-widest text-white title-font"
          >
            FEATURES
          </h2>
          <h1 class="mb-6 text-5xl font-bold text-white leading-snug">
            Feature overview
          </h1>
          <p class="flex items-left mb-2 text-white opacity-80">
            <span
                class="inline-flex sm:items-left justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full"
            >
              <img src="@/assets/images/hook.svg"/>
            </span>
            Communicate with other players between different Minecraft
            servers.
          </p>
          <p class="flex sm:items-left mb-2 text-white opacity-80">
            <span
                class="inline-flex items-left justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full"
            >
              <img src="@/assets/images/hook.svg"/>
            </span>
            Get a golden prefix in chat if you have LabyMod+.
          </p>
          <p class="flex sm:items-left mb-2 text-white opacity-80">
            <span
                class="inline-flex items-left justify-center flex-shrink-0 w-6 h-6 mr-2 rounded-full"
            >
              <img src="@/assets/images/hook.svg"/>
            </span>
            Send emotes to the Global Chat with the EmoteChat addon.
          </p>
        </div>
        <div
            class="shadow-2xl items-center rounded-lg w-5/6 mb-10 lg:max-w-lg lg:w-full md:w-1/2 md:mb-0"
        >
          <video
              class="rounded-lg transform transition hover:scale-105 duration-700 ease-in-out"
              autoplay
              loop
              muted
          >
            <source src="@/assets/images/features.mp4" type="video/mp4"/>
          </video>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HomeComponent",
  data: function () {
    return {
      stats: {
        registered: "/",
        online: "/",
        messages: "/",
      },
    };
  },
  methods: {
    async updateStats() {
      try {
        const response = await fetch("https://api.globalchataddon.de/stats");
        this.stats = await response.json();
      } catch (error) {
        console.error("Failed to request stats", error);
      }
    }
  },
  async mounted() {
    this.updateStats();
  }
};
</script>
