<template>
  <footer class="flex items-end justify-center px-4 text-gray-100">
    <div class="container py-6">
      <h1 class="text-center text-lg font-bold lg:text-2xl">
        Visit our social media sites<br/>
        for more information
      </h1>
      <div class="flex justify-center mt-6">
        <div class="rounded-lg">
          <button
              @click="twitter()"
              class="background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1"
              type="button"
          >
            <img src="@/assets/images/twitter.svg"/>
          </button>
          <button
              @click="discord()"
              class="background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1"
              type="button"
          >
            <img src="@/assets/images/discord.svg"/>
          </button>
        </div>
      </div>
      <hr class="h-px mt-6 bg-gray-700 border-none"/>
      <div class="flex flex-col items-center justify-between mt-6 md:flex-row">
        <div>
          <a class="px-4 text-sm"
          >© 2021 GlobalChatAddon, all rights reserved</a
          >
        </div>
        <div class="flex mt-4 md:m-0">
          <div class="-mx-4">
            <router-link to="/privacy"
            ><a class="px-4 text-sm">Privacy</a></router-link
            >
            <router-link to="/imprint"
            ><a class="px-4 text-sm">Imprint</a></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  methods: {
    twitter: function () {
      window.open("https://twitter.com/GlobalChatAddon", "_blank");
    },
    discord: function () {
      window.open("https://discord.GlobalChatAddon.de", "_blank");
    },
  },
};
</script>
