<template>
  <Disclosure as="nav" v-slot="{ open }">
    <div class="mx-auto p-5 px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <router-link to="/">
          <div class="flex-shrink-0 flex items-center">
            <img width="32" height="32" src="@/assets/images/logo.png"/>
            <h1 class="px-2 text-xl text-white text-center my-auto uppercase font-bold">GlobalChat</h1>
          </div>
          </router-link>
          <div class="hidden sm:block sm:ml-6 p-1">
            <div class="flex space-x-4">
              <router-link v-for="item in navigation" :key="item.name" :to="item.href">
                <a :class="[item.current ? 'bg-brand-white10 text-white' : 'text-gray-300 hover:text-white', 'items-center px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
              </router-link>
            </div>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <router-link to="/dashboard">
            <button
                class="items-center px-6 py-2 font-semibold text-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none"
            >
              Dashboard
            </button>
          </router-link>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 items-center justify-center">
        <router-link v-for="item in navigation" :key="item.name" :to="item.href">
          <a :class="[item.current ? 'bg-brand-white10 text-white' : 'text-gray-300 hover:text-white', 'items-center block px-3 py-2 rounded-md text-base font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
        </router-link>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'

const navigation = [
  { name: 'Home', href: '/', current: true },
  { name: 'Shop', href: '/shop', current: true },
]

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    XIcon,
  },
  setup() {
    return {
      navigation,
    }
  },
}
</script>
