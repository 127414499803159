import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/chatlog',
        name: 'Chatlog',
        component: () => import('../views/Chatlog.vue')
    },
    {
        path: '/shop',
        name: 'Shop',
        component: () => import('../views/Shop.vue')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue')
    },
    {
        path: "/dashboard/discord/oauth",
        name: "DiscordAuth",
        component: () => import('../views/DiscordAuth.vue')
    },
    {
        path: "/dashboard/labymod/oauth",
        name: "LabyModAuth",
        component: () => import('../views/LabyModAuth.vue')
    },
    {
        path: "/dashboard/userinfo/:name",
        name: "UserInfo",
        component: () => import('../views/UserInfo.vue')
    },
    {
        path: '/imprint',
        name: 'Imprint',
        component: () => import('../views/Imprint.vue')
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('../views/Privacy.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
