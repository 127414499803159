<template>
  <body id="app">
  <div class="min-h-screen flex flex-col container mx-auto px-2 text-white">
    <Navbar/>
    <div class="flex-grow">
      <router-view :key="$route.fullPath"/>
    </div>
    <Footer/>
  </div>
  </body>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    Footer
  },
};
</script>
