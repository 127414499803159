import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

import VueCookies from 'vue3-cookies'
import Notifications from 'vue3-vt-notifications'


createApp(App).use(VueCookies).use(router).use(Notifications).mount('#app')
